import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingScreen from "~components/loading-screen/LoadingScreen";

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({
  children
}: AuthGuardProps) {

  const { pathname, push } = useRouter()

  const {
    user
  } = useSelector((state: any) =>
    state.auth
  )

  const [rendered, setRendered] = useState(false)

  const checkAuth = async () => {

    if (
      user &&
      !pathname.includes('dashboard')
    )
      await push('/dashboard')
    else if (
      !user &&
      pathname.includes('dashboard')
    )
      await push('/login')

    setRendered(true)
  }

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <>
      {rendered ?
        children
        :
        <LoadingScreen />
      }
    </>
  )
}
