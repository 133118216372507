import Head from 'next/head'
import AuthGuard from '~auth/AuthGuard'
import LoginLayout from '~layouts/login/LoginLayout'
import Login from '~screens/auth/Login'

function LoginPage() {
  return (
    <>
      <Head>
        <title> Login |  Songoven Artist</title>
      </Head>

      <AuthGuard>
        <LoginLayout>
          <Login />
        </LoginLayout>
      </AuthGuard>
    </>
  )
}

export default LoginPage