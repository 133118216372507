import {
  Link,
  Stack,
  Typography
} from '@mui/material';
import NextLink from 'next/link';
import AuthLoginForm from './components/AuthLoginForm';

function Login() {

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          my: 0,
          position:
            'relative'
        }}
      >
        <Typography
          variant="h4"
        >
          Login
        </Typography>
      </Stack>
      <AuthLoginForm />
      <Link href={"#"}
        component={NextLink}
        variant="body2"
        color="inherit"
        sx={{
          position: 'absolute',
          top: 40,
          right: 40,
        }}
      >
        Need help?
      </Link>
    </>
  );
}

export default Login