import {
  Box,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import cls from 'classnames';
import {
  useSearchParams
} from 'next/navigation';
import Logo from 'public/assets/icons/logo.svg';
import Image from '~components/image';
import {
  loginLeftWidth
} from '~config/constants';
import { getUsername } from '~helper/index';
import { useArtistProfileQuery } from '~screens/profile/services/profileApi';
import {
  StyledContent,
  StyledRoot,
  StyledSection,
  StyledSectionBg
} from './styles';

import { IMAGES_PREFIX } from '~config/config-global';

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

function LoginLayout({
  children
}: Props) {

  const searchParams: any = useSearchParams()

  const userId = searchParams.get('userId') || ""

  const {
    palette: {
      primary
    }
  }: any = useTheme();

  const {
    data: artistData = {},
  } = useArtistProfileQuery(
    userId, {
    skip: !userId
  })

  const {
    firstName,
    lastName,
    stageName,
  } = artistData?.artist || {}

  return (
    <StyledRoot>
      <StyledSection
        sx={{
          border: '0px solid red',
          justifyContent: 'center !important',
          alignItems: 'flex-start !important',
          pb: '10px',
          px: '130px'
        }}
      >
        <Box
          className={cls(
            'border-red-700 border-0',
            'mb-5'
          )}
        >
          <Logo
            height={100}
            width={180}
          />
        </Box>
        <Typography
          variant="h1"
          sx={{
            border: '0px solid red',
            mb: '40px',
            width: loginLeftWidth,
          }}
        >
          {"Hi "}
          <span
            style={{
              color: primary.main
            }}
          >
            {getUsername(
              stageName,
              firstName,
              lastName,
            )}
          </span>
        </Typography>
        <Typography
          sx={{
            border: '0px solid red',
            mb: '10px',
            width: loginLeftWidth,
            fontSize: 17,
          }}
        >
          "One good thing about music, when it hits you, you feel no pain." - Bob Marley
        </Typography>
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={IMAGES_PREFIX + '/assets/images/login-left.png'}
          sx={{
            width: loginLeftWidth,
            // px: '10px',
            border: '0px solid red',
            objectFit: 'contain'
          }}
        />

        <StyledSectionBg
          className='auth-layout-bg'
        />

      </StyledSection>

      <StyledContent>
        <Stack
          sx={{
            width: 1,
            border: '0px solid red'
          }}
        >
          {children}
        </Stack>
      </StyledContent>

    </StyledRoot>
  );
}

export default LoginLayout