import { array, number, object, ref, string } from 'yup';

const confirm_password_validator = string()
    .oneOf([ref('newPassword')], '* Passwords must match')
    .required()
    .label('* Confirm Password');
const otp_validator = string()
    .required('One Time Code is required')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits');
const email_validator = string().email().required().label('Email');
const password_validator = string().required('Password is required');
const gender_validator = number().required().label('Gender');
const country_validator = string().required().label('Country');
const phone_number_validator = number().required().label('Phone Number');
const address_validator = string().required().label('Address');
const bio_validator = string().required().label('Bio');
const genre_validator = array()
    .min(1, 'Please select atleast 1 genre')
    .max(3, 'You can only select maximum of 3 genre')
    .required()
    .label('Genre');
const checked_music_traits_validator = array()
    .of(string())
    .min(1, 'Please select at least one music trait.');
const checked_topics_validator = array()
    .of(string())
    //.min(1, 'Please select at least one topic.')
    ;
const order_song_dto_validator = object().shape({
    name: string().required('Name is required.'),
    lyrics: string()
        .required('Lyrics are required.')
        .min(6, 'Lyrics must be at least 6 characters long.')
        .max(2500, 'Lyrics cannot exceed 600 characters.'),
    audioFile: string().required('Audio file is required.'),
});
const playback_file_dtos_validator = string().when('isPlayback', {
    is: (field: string) => {
        return field;
    },
    then: () => array().required('Audio file is required.'),
    otherwise: () => array().notRequired(),
});

const required_validator = (label: string) => string().required().label(label);

export {
    otp_validator,
    bio_validator,
    genre_validator,
    email_validator,
    gender_validator,
    country_validator,
    address_validator,
    password_validator,
    phone_number_validator,
    checked_topics_validator,
    order_song_dto_validator,
    confirm_password_validator,
    playback_file_dtos_validator,
    checked_music_traits_validator,
    required_validator,
};
