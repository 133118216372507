import {
  LoadingButton
} from '@mui/lab';
import {
  Box,
  Link,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import NextLink from 'next/link';
import {
  useRouter
} from 'next/router';
import {
  useForm
} from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  PATH_AUTH
} from '~config/routes/paths';
import loginSchema from '../schema/login.schema';
import {
  useLoginMutation
} from '../services/authApi';

function AuthLoginForm() {

  const {
    push
  } = useRouter()

  const [
    login,
    { isLoading }
  ] = useLoginMutation()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver:
      loginSchema
  });

  const handleLogin
    = handleSubmit(async data => {

      const response: any
        = await login({
          ...data,
          role: 'Artist',
        })

      const {
        error,
        data:
        respData
      } = response || {}

      if (respData) {
        toast.success("Logged in successfully!")
        push("/dashboard")
      }
      else if (error)
        toast.error(error?.data?.message)
    })

  const handleResetPassword = () => {
    push(PATH_AUTH.resetPassword)
  }

  return (
    <>
      <Stack spacing={3}>

        <TextField
          variant={'outlined'}
          fullWidth
          type="email"
          label="Email"
          {...register("email")}
        />
        <Typography
          variant="caption"
          fontWeight="medium"
          color="error"
        >
          {errors.email?.message}
        </Typography>
        <TextField
          variant={'outlined'}
          fullWidth
          type="password"
          label="Password"
          {...register("password")}
        />
        <Typography
          variant="caption"
          fontWeight="medium"
          color="error"
        >
          {errors.password?.message}
        </Typography>
      </Stack>

      <Stack
        alignItems="flex-end"
        sx={{ my: 2 }}
      >
        <Box
          onClick={handleResetPassword}
          color="inherit"
          sx={{
            textDecoration:
              'underline',
            cursor: 'pointer'
          }}
        >
          Forgot password?
        </Box>
      </Stack>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
        onClick={handleLogin}
        style={{
          backgroundColor:
            "#EF7484"
        }}
      >
        Login
      </LoadingButton>
    </>
  );
}

export default AuthLoginForm