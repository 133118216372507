import { Box, CircularProgress } from '@mui/material'

const Loader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100px',
                alignItems: 'center'
            }}
        >
            <CircularProgress />
        </Box>
    )
}

export default Loader