import { Box, BoxProps, Link } from '@mui/material'
import NextLink from 'next/link'
import AppLogoMini from 'public/assets/icons/logo-mini.svg'
import AppLogo from 'public/assets/icons/logo.svg'
import { forwardRef } from 'react'
import { useSettingsContext } from '~components/settings'
export interface LogoProps extends BoxProps {
  disabledLink?: boolean,
  isMini?: boolean
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {

    const { themeLayout } = useSettingsContext()

    const logo = (
      <>
        {themeLayout == 'vertical' ?
          <AppLogo
            height={40}
            width={125}
          />
          :
          <Box
            sx={{
              my: 2
            }}
          >
            <AppLogoMini
              height={40}
              width={90}
            />
          </Box>
        }
      </>
    )

    if (disabledLink) {
      return logo
    }

    return (
      <Link component={NextLink} href="#" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    )
  }
)

export default Logo
